<template>
  <v-app>
    <!-- <v-app-bar v-if="appFrame" app color="#1e2428" dark> -->
    <v-app-bar v-if="appFrame && !isMobile" clipped-left app color="#ffffff"
      style="background-color: #ffffff !important; " elevation="0" dark>
      <v-text-field v-if="$store.state.visibleSearch" v-model="search" filled light hide-details dense class="mx-6"
        :placeholder="`Buscar ${$store.state.searchTerm}`" prepend-inner-icon="fa-search" outlined
        style="border-radius: 10px !important; position: absolute; left: 50%; width: 400px; transform: translateX(-50%);">
        <template v-slot:prepend-inner>
          <v-icon small class="mt-1 mr-2">fa-search</v-icon>
        </template>
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="user-descrip" style="color: #1e2428;" v-if="user">
        <div>
          <v-card-title class="font-weight-bold" style="letter-spacing: 0.125em; line-height: 1.6rem">{{ user.name
            }}</v-card-title>
          <v-card-subtitle class="font-weight-light"
            style="margin-top: -22px; letter-spacing: 0.125em">Administrador</v-card-subtitle>
        </div>
        <img src="@/assets/user-01.svg" style="object-fit: cover;" class="photo" alt="" srcset="" />

      </div>
    </v-app-bar>
    <div v-if="appFrame && isMobile" class="navbar-mobile" style="border-radius: 0px !important;">
      <v-container class="d-flex justify-space-between pa-0">

        <v-avatar size="40" class="ml-2">
          <img :src="business?.logo.original" alt="" width="100%">
        </v-avatar>
        <img src="@/assets/Logol.svg" class="mr-2" alt="" width="70px">

        <v-btn @click="logOut" icon color="primary">
          <v-icon>fa-solid fa-right-from-bracket</v-icon>

        </v-btn>

      </v-container>
      <v-divider></v-divider>
      <v-text-field v-if="$store.state.visibleSearch" v-model="search" rounded filled hide-details clearable dense
        full-width :placeholder="`Buscar ${$store.state.searchTerm}`" prepend-inner-icon="fa-search"
        style="border-radius: 10px !important ; width: 100%; margin-top: 10px;">
        <template v-slot:prepend-inner>
          <v-icon small class="mt-1 mr-2">fa-search</v-icon>
        </template>
      </v-text-field>

    </div>
    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />
    <v-main>
      <router-view class="px-6" />
    </v-main>
    <v-navigation-drawer permanent v-if="navBar && !isMobile" class="navigation" app
      style="border-radius: 20px !important;" color="primary" dark>
      <v-img src="@/assets/SVG/Logo.svg" width="50%" class="ma-auto my-6" />

      <v-list nav dense dark>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.link" class="pl-5" color="#fff">
          <v-list-item-icon>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-4">
          <v-btn block color="#000" style="border-radius: 10px" @click="logOut">
            <v-icon small class="mr-2">fa-sign-out-alt</v-icon>

            Cerrar Sesión</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-bottom-navigation v-model="value" background-color="primary"
      style="border-radius: 20px 20px 0px 0px  !important;" dark fixed nav v-if="navBar && isMobile" shift>
      <v-btn v-for="item in items" :key="item.title" :to="item.link">
        <span>{{ item.title }}</span>

        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>


    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import Snackbar from "@/components/snackbar";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    appFrame: false,
    navBar: false,
    user: null,
    business: null,
    isMobile: window.innerWidth > 768 ? false : true,
    menuSudo: [
      {
        title: "Reporte de Beneficios",
        icon: "fa-regular fa-file-lines",
        link: "/salesCoupons",
      },
      { title: "Beneficios", icon: "fa-solid fa-ticket", link: "/coupons" },
      {
        title: "Ciudades",
        icon: "fa-solid  fa-city",
        link: "/cities",
      },
      {
        title: "Categorias",
        icon: "fa-solid  fa-tags",
        link: "/categories",
      },
      { title: "Secciones", icon: "fa-solid fa-stream", link: "/sections" },
      { title: "Comercios", icon: "fa-solid fa-building", link: "/business" },
      { title: "Comercios para Validar", icon: "fa-solid fa-building-circle-check", link: "/businessPending" },
      { title: "Influencers", icon: "fa-solid fa-user-group", link: "/users" },
      {
        title: "Usuarios Admin",
        icon: "fa-solid fa-users",
        link: "/usersAdmin",
      },
      {
        title: "Invitaciones Eventos",
        icon: "fa-solid fa-calendar-check",
        link: "/launchColab",
      },
    ],

    menuAdmin: [
      {
        title: "Reportes",
        icon: "fa-solid fa-clipboard-check",
        link: "/salesCoupons",
      },
      {
        title: "Redimir",

        icon: "fa-solid fa-ticket",
        link: "/redemption",
      },
    ],

    baseRoute: {
      sudo: "/salesCoupons",
      admin: "/salesCoupons",
    },
  }),
  computed: {
    ...mapState(["role", "selectedBusiness", "search"]),
    items() {
      if (this.user?.type == "sudo" && this.user?.role == "sudo") {
        return this.menuSudo;
      } else if (this.user?.type == "admin" && this.user?.role == "admin") {
        return this.menuAdmin;
      } else {
        return [];
      }
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
  },
  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      // this.titlepage(e.meta.titlepage);
      if (e.meta.allowedRoles && !e.meta.allowedRoles.includes(this.role)) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    ...mapActions(["titlepage", "setUser", "Alert_", "setUserValidity_"]),
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.$router.push({
              path: "/",
            });
            this.selectedBusiness_l = null;
            this.addBusiness(null);
          });
      }
    },
  },
  mounted() {
    this.appFrame = this.$route.meta.appFrame;
    this.navBar = this.$route.meta.navBar;

    fb.auth().onAuthStateChanged((authUser) => {
      if (this.$route.meta.basePath) {
        if (authUser) {
          this.$binding(
            "user",
            db.collection(`appUsers`).doc(authUser.uid)
          ).then((user) => {
            console.log(user)
            this.user = user;
            if (!user.type || user.active == false) {
              console.debug("no entra");
              this.setUserValidity_(false);
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();
            } else {
              this.setUser(user);

              console.log(user)
              if (user.businesses && user.businesses.length > 0) {
                this.$binding("business", db.collection("businesses").doc(user.businesses[0]));
              }
              console.log(user);
              console.log('ss', this.$router.currentRoute.name)
              if (this.$router.currentRoute.name === "login") {
                this.$router.push({
                  path: this.baseRoute[this.user.type],
                });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                //validating user permissions for this route
                this.$router.push({ path: this.baseRoute[user.type] });
              }
            }
          });
        } else {
          this.setUser(null);
          this.loading = false;
          var rutes = [
            "cities",
            "business",
            "coupons",
            "users",
            "salesCoupons",
            "redemption",
            "usersAdmin",
            "home",
          ];
          if (rutes.includes(this.$route.name) && !this.user)
            this.$router.push({ path: "/" });
        }
      }
    });
  },
};
</script>

<style>
.navigation {
  margin: 10px;
  height: calc(100vh - 20px) !important;


}

.navbar-mobile {
  width: 101vw;
  height: auto;
  position: sticky;
  top: -1px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
  border-radius: 0px !important;
  z-index: 1000;
  transition: 0.3s ease-in-out;
  padding: 5px;
}
</style>